<template>
  <BaseGuestRoomDailyReport>
    <template v-slot:default >
      <v-card class="pa-5 mt-5" elevation="0" style="width:800px">
        <div class="mt-5 mb-3 d-flex align-baseline">
          <p class="facName">{{ $t('common.facility') }}</p>
          <v-col cols="1" class="p-0">
            <v-select
              :items="facilityList"
              @change="eventSelectFac($event)"
              dense
              small
              color="var(--text__gray)"
              outlined
              hide-details
              class="v-select-custom ml-2 facility-list"
              v-model="selected"
            ></v-select>
          </v-col>
        </div>
        <v-data-table
          :headers="headers"
          :items="facilityDailyReportList.items"
          hide-default-footer
          disable-pagination
          :no-data-text="$t('rules.noData')"
          class="elevation-1"
        >
        <template v-slot:item.edit="{item}">
          <v-btn
            class="mx-2 pencil"
            fab
            dark
            x-small
            color="#00000029"
            :disabled="!checkUser"
            @click="loadDialog(item.status, item.facilityId, item.reportDate, item.id, item.type)"
          >
            <v-icon
              dark
              size="18"
              style="color: #fff"
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.facility="{ item }"> {{ item.facility.name }} </template>
        <template v-slot:item.createdAt="{ item }"> {{ new Date(item.createdAt).toISOString().substr(0, 10) +" " + new Date(item.createdAt).toString().substr(15, 6) }} </template>
        </v-data-table>
      </v-card>
      <!-- dialog -->
      <EditReportDialog
        v-if="visible"
        :visible="visible"
        @close="visible = false"
        @fetchData="getFacilityDailyList"
        @reload="reloadReportData"
        :type="currentReportType"
        :reportDate="currentReportDate"
        :facilityName="currentFacility"
        :facilityId="currentFacilityId"
        :id="currentReportId"
      />
    </template>
  </BaseGuestRoomDailyReport>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import BaseGuestRoomDailyReport from '@/components/GuestRoomDailyReport/BaseGuestRoomDailyReport'
import EditReportDialog from './editReportDialog'
import { FACILITY_DAILY_REPORT_LIST } from '@/api/graphql/dailyReport/facility-daily-report'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { mapActions, mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'DailyReportList',
  components: {
    BaseGuestRoomDailyReport,
    EditReportDialog
  },
  data () {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      facilityListItem: [],
      facilityList: ['全て'],
      facilityId: null,
      facilityDailyReportList: [],
      selected: '全て',
      items: [{
        reportDate: '2021-05-11',
        facility: 'XXXXXX',
        createdDate: '2021-05-12 12:00',
        edit: 24,
        status: '未承認'
      },
      {
        reportDate: '2021-05-12',
        facility: 'XXXXXX',
        createdDate: '2021-05-12 12:00',
        edit: 24,
        status: '未承認'
      },
      {
        reportDate: '2021-05-13',
        facility: 'XXXXXX',
        createdDate: '2021-05-12 12:00',
        edit: 24,
        status: '未承認'
      },
      {
        reportDate: '2021-05-14',
        facility: 'XXXXXX',
        createdDate: '2021-05-12 12:00',
        edit: 24,
        status: '未承認'
      }],
      headers: [
        { text: this.$t('reports.dailyReportDate'), value: 'reportDate' },
        { text: this.$t('common.facility'), value: 'facility' },
        { text: this.$t('reports.createdDate'), value: 'createdAt' },
        { text: this.$t('reports.editApproval'), value: 'edit' },
        { text: this.$t('reports.status'), value: 'status' }
      ],
      // Dialog ----------------
      visible: false,
      currentFacility: null,
      currentReportDate: null,
      currentReportId: null,
      currentReportType: null,
      currentFacilityId: null
      // -----------------------
    }
  },

  created () {
    this.getFacilityDailyList()
    this.getFacilityList()
  },

  methods: {
    ...mapMutations(['setDailyReportStatus']),
    ...mapActions(['fetchDetailData']),
    async reloadReportData () {
      await this.fetchDetailData({ facilityId: this.currentFacilityId, reportDate: this.currentReportDate })
    },
    async loadDialog (status, facilityId, reportDate, id, type) {
      await this.setDialog(status, facilityId, reportDate, id, type)
      this.visible = true
    },
    async setDialog (status, facilityId, reportDate, id, type) {
      this.setDailyReportStatus(status)
      await this.fetchDetailData({ facilityId: facilityId, reportDate: reportDate })
      this.currentFacilityId = facilityId
      this.currentReportType = type
      this.currentReportDate = reportDate
      this.currentReportId = id
      this.currentFacility = this.facilityListItem.find(item => item.id === facilityId)?.name || ''
    },
    async getFacilityDailyList () {
      await this.$apollo.query({
        query: gql`${FACILITY_DAILY_REPORT_LIST}`,
        variables: {
          facilityId: this.facilityId,
          pagination: {
            skip: 0,
            take: 500
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.facilityDailyReportList = data.data.facilityDailyReportList
        this.items = this.facilityDailyReportList.items
      }).catch((error) => {
        console.error(error)
      })
    },

    async getFacilityList () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        for (let i = 0; i < data.data.facilityList.length; i++) {
          this.facilityListItem = data.data.facilityList
          this.facilityList.push(data.data.facilityList[i].name)
        }
      }).catch((error) => {
        console.error(error)
      })
    },

    eventSelectFac (event) {
      if (event === '全て') {
        this.facilityId = null
      } else {
        this.facilityId = this.facilityListItem[this.facilityList.indexOf(event) - 1].id
      }
      this.getFacilityDailyList()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-btn {
    width:25px;
    height:25px
  }
  .v-input {
    width: 100px;
  }
  .text-start span{
    color: #757575;
  }
}
  .p-0 {
    padding: 0 !important
  }
  .facName {
    font-size: 10px;
    color: #000000;
  }
  .pencil {
  background-color: #757575 !important;
}
</style>
