import gql from 'graphql-tag'

export const FACILITY_DAILY_REPORT_LIST = gql`
  query ($facilityId: Int, $pagination: PaginationInput){
  facilityDailyReportList (facilityId: $facilityId, pagination: $pagination) {
  items {
      id reportDate
      facility {name}
      type
      createdAt
      status
      facilityId
      }
    }
 }
`
