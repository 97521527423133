<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog" width="1000">
      <v-card>
        <v-card-title>
          <span class="custom-title font-weight-bold"
            >{{ reportDate + " " + facilityName }} 日報</span
          >
        </v-card-title>
        <v-card-text class="d-flex justify-space-between align-center">
          <div class="d-flex">
            <div>
              <div class="pt-3">
                <span class="custom-size">ステータス</span>
              </div>
              <div
                v-if="setStatus !== '差し戻し' && setStatus !== '承認済'"
                class="pt-1"
              >
                <span class="cus-size-value"> {{ dailyReportStatus }} </span>
              </div>
            </div>
            <div class="pt-5 pl-5">
              <v-btn
                :disabled="setStatus === '承認済'"
                @click="confirmStatus('承認済')"
                color="#1873D9"
                class="white--text ml-2 pa-5 custom-btn"
              >
                {{ $t("buttons.approval") }}
              </v-btn>
              <v-btn
                :disabled="setStatus === '差し戻し'"
                @click="confirmStatus('差し戻し')"
                color="#1873D9"
                class="white--text ml-2 pa-5 custom-btn"
              >
                {{ $t("buttons.remand") }}
              </v-btn>
            </div>
          </div>
          <div>
            <v-btn
              color="#C92D2D"
              class="white--text ml-2 pa-5"
              @click="closeDialog"
            >
              <v-icon class="mr-1"> mdi-close </v-icon>
              {{ $t("buttons.close") }}
            </v-btn>
            <v-btn
              color="#1873D9"
              class="white--text ml-2 pa-5"
              @click="handleDailyUpdate"
            >
              {{ $t("buttons.save") }}
            </v-btn>
          </div>
        </v-card-text>
        <template v-if="type === 'TVS'">
          <v-card-text>
            <div class="mt-4 ml-4 custom-size-title">
              <span>{{ $t("reports.cashCheck") }}</span>
            </div>
            <v-card class="mt-4" max-width="1250px">
              <v-form ref="reportForm" lazy-validation>
                <v-card-text>
                  <v-row class="pa-5 d-flex justify-space-between">
                    <v-col
                      v-for="(item, index) in getMoneyList()"
                      :key="index"
                      cols="7"
                      sm="1"
                      class="pb-0"
                    >
                      <v-row>
                        <span
                          class="
                        text-size-normal
                        font-weight-bold
                        black--text
                        custom-size
                      "
                        >
                          {{ item.label }}
                        </span>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="item.value"
                          class="pa-0 small-message"
                          type="number"
                          :rules="rules.requiredNumber"
                          validate-on-blur
                          @keydown="checkNumber"
                          @change="changePrice($event, index, item.label)"
                        ></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div class="mt-3 d-flex align-center">
                    <span
                      class="
                    text-size-normal
                    font-weight-bold
                    black--text
                    custom-size
                    mr-2
                  "
                      >{{ $t("reports.moneyTotal") }}</span
                    >
                    ¥{{ getMoneyTotal() }}
                  </div>
                </v-card-text>
                <v-card-text class="d-flex pt-2">
                  <v-col
                    cols="2"
                    class="pl-0 text-size-normal custom-icon-select"
                  >
                    <span class="text-size-normal black--text custom-size">{{
                      $t("reports.cashRegister")
                    }}</span>
                    <v-select
                      class="custom-select"
                      v-model="isResetCashAdjustment"
                      @change="changeAjustmentSelect"
                      :items="resSetCashOptions"
                      item-text="text"
                      item-value="value"
                      hide-details
                      dense
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <span class="text-size-normal custom-size">{{
                      $t("reports.adjustmentAmount")
                    }}</span>
                    <tvos-int-input
                      jpy
                      negative
                      :disabled="disable"
                      :value="
                        isResetCashAdjustment
                          ? 0
                          : reportDayData.report &&
                            reportDayData.report.cashAdjustment
                      "
                      @change="reportDayData.report.cashAdjustment = $event"
                      dense
                      required
                    />
                  </v-col>
                  <v-col cols="8" class="pr-0">
                    <span class="text-size-normal custom-size">{{
                      $t("reports.reason")
                    }}</span>
                    <v-text-field
                      :disabled="disable"
                      dense
                      :rules="rules.text"
                      :value="
                        reportDayData.report &&
                          reportDayData.report.cashAdjustmentReason
                      "
                      @change="reportDayData.report.cashAdjustmentReason = $event"
                      validate-on-blur
                    >
                    </v-text-field>
                  </v-col>
                </v-card-text>
              </v-form>
            </v-card>
            <div class="ml-4 mt-4 custom-size-title">
              <span>{{ $t("reports.dailySalesReport") }}</span>
            </div>
            <v-card class="mt-4" max-width="1250px">
              <v-data-table
                v-if="reportDayData.details"
                :headers="headers"
                :items="reportDayData.details"
                hide-default-footer
                :no-data-text="$t('rules.noData')"
                disable-pagination
                item-key="reportDate"
              >
                <template v-slot:[`item.amount`]="{ item }">
                  {{ formatCurrency(item.amount) }}
                </template>
                <template v-slot:[`item.attachments`]="{ item }">
                  <v-btn
                    v-if="
                      item.detail &&
                        item.detail.attachments &&
                        item.detail.attachments.length > 0
                    "
                    class="mx-2"
                    color="#1873D9"
                    dark
                    fab
                    x-small
                    @click="
                      downloadFacilityDailyReportAttachmentFile(
                        item.paymentMethodId,
                        item.name
                      )
                    "
                  >
                    <v-icon dark size="18"> mdi-cloud-download </v-icon>
                  </v-btn>
                  <span v-else class="red-text">なし</span>
                </template>
                <template v-slot:[`item.check`]="{item}">
                  <v-btn class="mx-2" color="#1873D9" v-if="showCheckIcon(item)" dark fab x-small @click="checkDialog(item)">
                    <v-icon dark size="18"> mdi-account-check </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
            <v-card class="mt-4" max-width="1250px">
              <span
                class="ml-4 font-weight-bold mt-5 text-size-normal custom-size"
              >
                {{ $t("reports.memoComment") }}
              </span>
              <v-textarea
                v-if="reportDayData && reportDayData.report"
                ref="reportNote"
                class="pb-4 report-note"
                dense
                rounded
                :rules="rules.reportNote"
                :value="reportDayData.report.notes"
                @change="reportDayData.report.notes = $event"
                validate-on-blur
              ></v-textarea>
            </v-card>
          </v-card-text>

          <VoucherCheckDialog
            v-if="checkVoucherVisible"
            :isReported="isConfirmed"
            :visible="checkVoucherVisible"
            :date="reportDate"
            :facilitySelected="facilityId"
            :itemReport="itemReport"
            fromList
            @close="checkVoucherVisible = false"
            @reloadData="$emit('reload')"
          />
          <CheckCreditCardDialog
            v-if="checkCreditCardVisible"
            :isReported="isConfirmed"
            :facilitySelected="facilityId"
            :date="reportDate"
            :itemReport="itemReport"
            :visible="checkCreditCardVisible"
            fromList
            @close="checkCreditCardVisible = false"
            @reloadData="$emit('reload')"
          />
        </template>

        <v-card-text v-else>
          <h2 class="pa-4 mt-4 custom-size-title">
            ホテルコンバージョン施設日報
          </h2>
          <div class="d-flex align-center">
            <label class="mr-2 mb-2 custom-size font-weight-bold"
              >ステータス</label
            >
            <v-select
              class="mt-0 pa-0 v-select-custom mb-2"
              hide-details
              outlined
              @change="filterStatus"
              :items="statusSelectList"
              v-model="statusSelected"
            />
          </div>
          <v-data-table
            class="elevation-1 table-custom"
            :headers="cnvReportTableHeader"
            :items="reportDayDataBookingsFiltered"
            :item-class="itemRowBackground"
            :no-results-text="$t('rules.noData')"
            :no-data-text="$t('rules.noData')"
            :footer-props="{
              itemsPerPageText:
                numLength +
                $t('common.cases') +
                itemStart +
                '~' +
                itemStop +
                $t('common.showing'),
              itemsPerPageOptions: [10, 20, 50, 100, 200, 500, 1000]
            }"
            @pagination="getNumOfPage"
          >
            <template v-slot:[`item.cleaning`]="{ item }">
              <v-menu
                offset-y
                max-width="150"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                  <template v-if="item.facilityDailyReportConversionDetail.cleaning">
                    {{
                      item.facilityDailyReportConversionDetail.cleaning ===
                      "Yes"
                        ? "あり"
                        : "なし"
                    }}
                  </template>
                  <span v-else class="text--red-dark">
                    未選択
                  </span>
                  </v-btn>
                </template>
                <v-card class="px-4">
                  <cleaning-yes-no
                    @change="saveEventCNV"
                    v-model="item.facilityDailyReportConversionDetail.cleaning"
                  />
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.cleaningFeeExempt`]="{ item }">
              <v-menu
                offset-y
                max-width="150"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail.cleaningFeeExempt
                      "
                      class="text--prm"
                    >
                      {{
                        item.facilityDailyReportConversionDetail
                          .cleaningFeeExempt === "Clients"
                          ? "顧客"
                          : "顧客と施設"
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未選択
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4">
                  <v-select
                    :items="cleaningFeeExemptList"
                    v-model="item.facilityDailyReportConversionDetail.cleaningFeeExempt"
                    item-text="name"
                    item-value="value"
                    class="cleaning-select"
                  >
                  </v-select>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.cleaningFeeExemptAmount`]="{ item }">
              <v-menu
                offset-y
                max-width="170"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail
                          .cleaningFeeExemptAmount
                      "
                      class="text--prm"
                    >
                      ¥{{
                        item.facilityDailyReportConversionDetail
                          .cleaningFeeExemptAmount
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未記入
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4 pt-4 pb-1">
                  <v-text-field
                    placeholder="清掃免除金額"
                    class="small-message"
                    :rules="rules.numberNotRequired"
                    type="number"
                    @input="checkCleaningFeeExemptAmount($event, item)"
                    @keydown="checkNumber"
                    outlined
                    dense
                    :value="item.facilityDailyReportConversionDetail.cleaningFeeExemptAmount"
                  >
                  </v-text-field>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.facilityFeeExempt`]="{ item }">
              <v-menu
                offset-y
                max-width="150"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail.facilityFeeExempt
                      "
                      class="text--prm"
                    >
                      {{
                        item.facilityDailyReportConversionDetail
                          .facilityFeeExempt === "Clients"
                          ? "顧客"
                          : "顧客と施設"
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未選択
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4">
                  <v-select
                    :items="cleaningFeeExemptList"
                    v-model="item.facilityDailyReportConversionDetail.facilityFeeExempt"
                    item-text="name"
                    item-value="value"
                    class="cleaning-select"
                  >
                  </v-select>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.facilityFeeExemptAmount`]="{ item }">
              <v-menu
                offset-y
                max-width="170"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="
                        item.facilityDailyReportConversionDetail
                          .facilityFeeExemptAmount
                      "
                      class="text--prm"
                    >
                      ¥{{
                        item.facilityDailyReportConversionDetail
                          .facilityFeeExemptAmount
                      }}
                    </span>
                    <span v-else class="text--inactive">
                      未記入
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4 pt-4 pb-1">
                  <v-text-field
                    placeholder="利用料免除金額"
                    class="small-message"
                    :rules="rules.numberNotRequired"
                    type="number"
                    @input="checkFacilityFeeExemptAmount($event, item)"
                    @keydown="checkNumber"
                    outlined
                    dense
                    :value="item.facilityDailyReportConversionDetail.facilityFeeExemptAmount"
                  >
                  </v-text-field>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.remarks`]="{ item }">
              <v-menu
                offset-y
                max-width="170"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    class="text--prm font-weight-bold"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-if="item.facilityDailyReportConversionDetail.remarks"
                      class="text--prm"
                    >
                      {{ item.facilityDailyReportConversionDetail.remarks }}
                    </span>
                    <span v-else class="text--inactive">
                      未記入
                    </span>
                  </v-btn>
                </template>
                <v-card class="px-4 pt-4 pb-1">
                  <v-text-field
                    placeholder="対応方法・理由"
                    class="small-message"
                    :rules="rules.remark"
                    outlined
                    dense
                    @input="checkRemarks($event, item)"
                    :value="item.facilityDailyReportConversionDetail.remarks"
                  >
                  </v-text-field>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn
                outlined
                small
                class="bg--white"
                :disabled="!item.facilityDailyReportConversionDetail"
                target="_blank"
                :href="$goToRoute.booking.url(item.id)"
                color="var(--bt__blue)"
              >
                {{ $t("buttons.edit") }}
              </v-btn>
            </template>
            <template v-slot:[`item.computed`]="{ item }">
              {{ item.computed && item.computed.status }}
            </template>
            <template v-slot:[`item.checkInDate`]="{ item }">
              {{ item.checkInDate.split("-").join("/") }}
            </template>
            <template v-slot:[`item.checkOutDate`]="{ item }">
              {{ item.checkOutDate.split("-").join("/") }}
            </template>
            <template v-slot:[`item.representative`]="{ item }">
              {{ item.representative && item.representative.name }}
            </template>
          </v-data-table>

          <v-card class="mt-6 py-3" max-width="1000px">
            <label class="ml-5 font-weight-bold custom-size">
              {{ $t("reports.memoComment") }}
            </label>
            <v-textarea
              ref="reportNote"
              class="mt-2"
              dense
              rounded
              :value="reportDayData.notes"
              @change="reportDayData.notes = $event"
              validate-on-blur
            ></v-textarea>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CONFIRM_OR_REJECT_FACILITY_DAILY_REPORT_HQ } from '@/api/graphql/guestRoomDailyReport/daily-report-list'
import { mapMutations, mapGetters } from 'vuex'
import { currency } from '@/utils/filters'
import { MAX_INT } from '@/constants/enum'
import { formatCurrency, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { listContractBackground } from '@/constants/contractColors'
import { UPDATE_CNV_FACILITY_DAILY_REPORT, UPDATE_TVS_FACILITY_DAILY_REPORT } from '@/api/graphql/guestRoomDailyReport/get-recorded-income-facility-day'
import VoucherCheckDialog from './voucherCheckDialog'
import CheckCreditCardDialog from './checkCreditCardDialog'
import { showCheckIcon } from '@/utils/dailyReport'
import gql from 'graphql-tag'
import CleaningYesNo from './components/CleaningYesNo.vue'

const SEARCH_ALL = '全て'

export default {
  components: { CleaningYesNo, VoucherCheckDialog, CheckCreditCardDialog },
  name: 'EditReportDialog',
  props: {
    visible: Boolean,
    reportDate: String,
    facilityName: String,
    facilityId: Number,
    type: String,
    id: [Number, String]
  },
  computed: {
    ...mapGetters(['dailyReportStatus', 'reportDayData', 'reportDayBookings', 'reportDayDataBookings']),
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
          if (this.type === 'TVS') {
            this.$refs.reportForm.resetValidation()
          }
          this.$refs.reportNote.resetValidation()
        }
      }
    },
    setStatus: {
      get () {
        return this.dailyReportStatus
      },
      set (value) {
        this.setDailyReportStatus(value)
      }
    },
    statusSelectList () {
      const statuses = this.reportDayDataBookings.map(item => item.computed.status).sort()
      statuses.unshift(SEARCH_ALL)
      return statuses
    },
    reportDayDataBookingsFiltered () {
      return this.reportDayDataBookings.filter(item => this.statusSelected === SEARCH_ALL || item.computed.status === this.statusSelected)
    },
    isConfirmed () {
      return !!this.reportDayData.report.hqConfirmedAt
    }
  },
  data () {
    return {
      disable: null,
      cleaningFeeExemptList: [
        {
          name: '顧客',
          value: 'Clients'
        },
        {
          name: '顧客と施設',
          value: 'ClientsAndFacilities'
        },
        {
          name: '未選択',
          value: null
        }
      ],
      isResetCashAdjustment: false,
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      statusSelected: SEARCH_ALL,
      resSetCashOptions: [
        { text: '無し', value: true },
        { text: 'あり', value: false }
      ],
      moneyList: [
        { value: null, label: '1円玉' },
        { value: null, label: '5円玉' },
        { value: null, label: '10円玉' },
        { value: null, label: '50円玉' },
        { value: null, label: '100円玉' },
        { value: null, label: '500円玉' },
        { value: null, label: '1000円札' },
        { value: null, label: '2000円札' },
        { value: null, label: '5000円札' },
        { value: null, label: '10000円札' }
      ],
      checkStatus: false,
      items: [
        {
          name: '現金',
          day: '123,456',
          primary: '今津康平',
          secondary: '今津康平'
        },
        {
          name: '現金2',
          day: '123,456',
          primary: '今津康平',
          secondary: '今津康平'
        },
        {
          name: '現金3',
          day: '123,456',
          primary: 'なし',
          secondary: 'なし'
        },
        {
          name: '現金4',
          day: '123,456',
          primary: 'なし',
          secondary: 'なし'
        },
        {
          name: '現金5',
          day: '123,456',
          primary: 'なし',
          secondary: 'なし'
        },
        {
          name: '現金6',
          day: '123,456',
          primary: 'なし',
          secondary: 'なし'
        }
      ],
      headers: [
        { text: this.$t('reports.kindName'), value: 'name' },
        { text: this.$t('reports.onTheDay'), value: 'amount' },
        {
          text: this.$t('reports.primaryConfirmer'),
          value: 'detail.check1stName'
        },
        {
          text: this.$t('reports.secondaryConfirmer'),
          value: 'detail.check2ndName'
        },
        {
          text: this.$t('common.fileDownload'),
          align: 'center',
          value: 'attachments'
        },
        {
          text: this.$t('reports.dailyCheck'),
          align: 'center',
          value: 'check'
        }
      ],
      cnvReportTableHeader: [
        {
          text: '清掃有無',
          value: 'cleaning'
        },
        {
          text: '清掃料免除区分',
          value: 'cleaningFeeExempt'
        },
        {
          text: '清掃免除金額',
          value: 'cleaningFeeExemptAmount'
        },
        {
          text: '利用料免除区分',
          value: 'facilityFeeExempt'
        },
        {
          text: '利用料免除金額',
          value: 'facilityFeeExemptAmount'
        },
        {
          text: '対応方法・理由',
          value: 'remarks'
        },
        {
          text: '予約編集',
          value: 'edit'
        },
        {
          text: '部屋',
          value: 'room.name'
        },
        {
          text: '代表者',
          value: 'representative'
        },
        {
          text: '予約ID',
          value: 'id'
        },
        {
          text: 'ステータス',
          value: 'computed'
        },
        {
          text: '部屋タイプ',
          value: 'room.roomType.name'
        },
        {
          text: 'チェックイン',
          value: 'checkInDate'
        },
        {
          text: 'チェックアウト',
          value: 'checkOutDate'
        }
      ],
      rules: {
        numberNotRequired: [
          v => (v >= 0 && v < MAX_INT) || !v || this.$t('rules.isInvalid')
        ],
        remark: [
          v => v?.length < 250 || !v || this.$t('rules.isInvalid')
        ],
        requiredNumber: [
          v =>
            (!!v && v < MAX_INT) ||
            v === 0 ||
            this.$t('rules.invalid', { title: '' })
        ],
        reportNote: [v => v?.length < 2001 || !v || this.$t('rules.isInvalid')],
        text: [v => v?.length < 256 || !v || this.$t('rules.isInvalid')]
      },
      itemReport: null,
      checkVoucherVisible: false,
      checkCreditCardVisible: false
    }
  },
  watch: {
    reportDayData: {
      deep: true,
      immediate: true,
      handler (newReport) {
        const report = newReport.report
        if (report) {
          this.isResetCashAdjustment = report.cashAdjustment === 0
          this.changeAjustmentSelect(this.isResetCashAdjustment)
          this.moneyList[0].value = this.reportDayData.report?.cash1
          this.moneyList[1].value = this.reportDayData.report?.cash5
          this.moneyList[2].value = this.reportDayData.report?.cash10
          this.moneyList[3].value = this.reportDayData.report?.cash50
          this.moneyList[4].value = this.reportDayData.report?.cash100
          this.moneyList[5].value = this.reportDayData.report?.cash500
          this.moneyList[6].value = this.reportDayData.report?.cash1000
          this.moneyList[7].value = this.reportDayData.report?.cash2000
          this.moneyList[8].value = this.reportDayData.report?.cash5000
          this.moneyList[9].value = this.reportDayData.report?.cash10000
        }
      }
    }
  },
  methods: {
    showCheckIcon,
    currency,
    formatCurrency,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setAlertSuccess',
      'setDailyReportStatus',
      'setAlertError',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),
    filterStatus (v) {
      v === '全て' ? (this.keySearch = null) : (this.keySearch = v)
    },
    checkDialog (item) {
      this.itemReport = item
      if (item.category === '商品券') {
        this.checkVoucherVisible = true
      } else if (item.category === 'クレジットカード') {
        this.checkCreditCardVisible = true
      }
    },
    handleDailyUpdate () {
      if (this.type === 'TVS') {
        if (
          this.$refs.reportForm.validate() &&
          this.$refs.reportNote.validate()
        ) {
          this.submitTVSFacilityDailyReport()
          this.$refs.reportForm.resetValidation()
          this.$refs.reportNote.resetValidation()
        }
      } else if (this.type === 'CNV' && this.$refs.reportNote.validate()) {
        this.submitCNVFacilityDailyReport()
        this.$refs.reportNote.resetValidation()
      }
    },
    async submitCNVFacilityDailyReport () {
      this.setLoadingOverlayShow()
      const arr = []
      this.reportDayDataBookings.length > 0 && this.reportDayDataBookings.map(item => {
        item.facilityDailyReportConversionDetail && arr.push({
          bookingId: parseInt(item.id),
          cleaning: item.facilityDailyReportConversionDetail.cleaning,
          cleaningFeeExempt: item.facilityDailyReportConversionDetail.cleaningFeeExempt,
          cleaningFeeExemptAmount: parseInt(item.facilityDailyReportConversionDetail.cleaningFeeExemptAmount),
          facilityFeeExempt: item.facilityDailyReportConversionDetail.facilityFeeExempt,
          facilityFeeExemptAmount: parseInt(item.facilityDailyReportConversionDetail.facilityFeeExemptAmount),
          remarks: item.facilityDailyReportConversionDetail.remarks
        })
      })
      const variables = {
        reportId: parseInt(this.id, 10),
        detail: arr,
        notes: this.reportDayData.notes
      }
      await this.$apollo
        .mutate({
          mutation: gql`${UPDATE_CNV_FACILITY_DAILY_REPORT}`,
          variables: variables
        })
        .then(data => {
          this.setAlertSuccess(this.$t('messages.successfulUpdate'))
          this.setLoadingOverlayHide()
        })
        .catch(async error => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_CNV_FACILITY_DAILY_REPORT, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError(this.$t('messages.updateError'), { root: true })
          }
        })
    },
    async downloadFacilityDailyReportAttachmentFile (paymentMethodId, name) {
      await this.$store.dispatch('downloadDailyReportAttachment', {
        facilityId: this.facilityId,
        reportDate: this.reportDate,
        paymentMethodId,
        name
      })
    },
    async submitTVSFacilityDailyReport () {
      this.setLoadingOverlayShow()
      const variables = {
        reportId: parseInt(this.id, 10),
        cash1Count: parseInt(this.moneyList[0].value),
        cash5Count: parseInt(this.moneyList[1].value),
        cash10Count: parseInt(this.moneyList[2].value),
        cash50Count: parseInt(this.moneyList[3].value),
        cash100Count: parseInt(this.moneyList[4].value),
        cash500Count: parseInt(this.moneyList[5].value),
        cash1000Count: parseInt(this.moneyList[6].value),
        cash2000Count: parseInt(this.moneyList[7].value),
        cash5000Count: parseInt(this.moneyList[8].value),
        cash10000Count: parseInt(this.moneyList[9].value),
        cashAdjustment: parseInt(this.reportDayData.report.cashAdjustment),
        cashAdjustmentReason: this.reportDayData.report.cashAdjustmentReason,
        notes: this.reportDayData.report.notes
      }
      await this.$apollo
        .mutate({
          mutation: gql`${UPDATE_TVS_FACILITY_DAILY_REPORT}`,
          variables: variables
        })
        .then(data => {
          this.setLoadingOverlayHide()
          this.setAlertSuccess(this.$t('messages.successfulUpdate'))
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_TVS_FACILITY_DAILY_REPORT, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)

          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError(this.$t('messages.updateError'), { root: true })
          }
        })
    },
    itemRowBackground (item) {
      if (item.blockRoom) {
        return 'bg-contract--block'
      }
      let contract = item.bookingType.code.trim()
      if (contract.includes('SP')) {
        contract = 'SP'
      }
      return listContractBackground[contract]
    },
    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
    },
    changePrice (v, index, label) {
      this.moneyList[index] = {
        value: parseInt(v),
        label
      }
    },
    changeAjustmentSelect (v) {
      this.disable = v
      if (v) {
        this.reportDayData.report.cashAdjustment = 0
      }
    },
    closeDialog () {
      this.$emit('close')
    },
    checkCleaningFeeExemptAmount (e, item) {
      if (e < MAX_INT) {
        item.facilityDailyReportConversionDetail.cleaningFeeExemptAmount = e
      }
      this.$forceUpdate()
    },
    checkFacilityFeeExemptAmount (e, item) {
      if (e < MAX_INT) {
        item.facilityDailyReportConversionDetail.facilityFeeExemptAmount = e
      }
      this.$forceUpdate()
    },
    checkRemarks (e, item) {
      if (e?.length < 250) {
        item.facilityDailyReportConversionDetail.remarks = e
      }
      this.$forceUpdate()
    },
    checkNumber (evt) {
      if (['e', 'E', '+', '-'].includes(evt.key)) {
        return evt.preventDefault()
      }
    },
    getMoneyTotal () {
      const cash1 = this.moneyList[0].value || 0
      const cash5 = this.moneyList[1].value || 0
      const cash10 = this.moneyList[2].value || 0
      const cash50 = this.moneyList[3].value || 0
      const cash100 = this.moneyList[4].value || 0
      const cash500 = this.moneyList[5].value || 0
      const cash1000 = this.moneyList[6].value || 0
      const cash2000 = this.moneyList[7].value || 0
      const cash5000 = this.moneyList[8].value || 0
      const cash10000 = this.moneyList[9].value || 0
      const moneyTotal =
        parseInt(cash1) +
        parseInt(cash5) * 5 +
        parseInt(cash10) * 10 +
        parseInt(cash50) * 50 +
        parseInt(cash100) * 100 +
        parseInt(cash500) * 500 +
        parseInt(cash1000) * 1000 +
        parseInt(cash2000) * 2000 +
        parseInt(cash5000) * 5000 +
        parseInt(cash10000) * 10000
      const result = this.formatCurrency(moneyTotal)
      return result
    },
    async confirmStatus (status) {
      this.setLoadingOverlayShow()
      this.setDailyReportStatus(status)
      let stattusEnum = ''
      if (status === '承認済') {
        stattusEnum = 'Confirmed'
      } else {
        stattusEnum = 'Rejected'
      }
      const variables = {
        facilityDailyReportId: this.id,
        status: stattusEnum
      }
      await this.$apollo
        .mutate({
          mutation: gql`${CONFIRM_OR_REJECT_FACILITY_DAILY_REPORT_HQ}`,
          variables: variables
        })
        .then(data => {
          this.setLoadingOverlayHide()
          this.setAlertSuccess(this.$t('messages.successfulUpdate'))
          this.$emit('fetchData')
        })
        .catch(async error => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(CONFIRM_OR_REJECT_FACILITY_DAILY_REPORT_HQ, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          } else {
            this.setAlertError(this.$t('messages.updateError'))
          }
        })
    },
    getMoneyList () {
      return this.moneyList
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .cleaning-select {
    .v-select__selections * {
      font-size: 14px !important;
    }
  }
  &.v-data-table {
    th{
      font-size: 14px !important;
    }
    tbody > tr > td {
      font-size: 16px !important;
    }
  }
  .table-custom {
    thead * {
      color: #757575 !important;
    }
    tbody > tr > td {
      font-size: 14px !important;
      max-width: 200px !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      overflow: hidden;
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        font-weight: bold !important;
      }
    }
  }
  .small-message {
    .v-messages__message {
      font-size: 11px !important;
      line-height: 1.5 !important;
    }
  }
  .v-data-table {
    thead tr th {
      color: #757575 !important;
    }
  }
  .theme--light.v-input textarea {
    border: 2px solid rgba(0, 0, 0, 0.16) !important;
    border-radius: 7px;
    padding-left: 8px !important;
  }
  .report-note {
    .v-text-field__details {
      margin-left: 24px !important;
      margin-top: 2px !important;
    }
  }
  .v-select__selection--comma {
    font-size: 10px !important;
  }
  .custom-select .v-select__selection--comma {
    font-size: 14px !important;
    color: #444444;
  }
  .custom-btn-picker .v-btn__content {
    font-size: 10px;
    color: #000000;
  }
  .custom-btn .v-btn__content {
    font-size: 14px;
    color: #ffffff !important;
  }
  .custom-icon-select .v-icon {
    color: #333333;
  }
}
.custom-size {
  color: #000000;
  font-size: 10px !important;
}
.custom-text-fac {
  font: 12px Noto Sans;
}
.custom-size-title {
  font-size: 20px !important;
  color: #000000;
  font: normal normal bold 20px/30px Noto Sans;
}
.red-text {
  color: #ff0000;
}
</style>
